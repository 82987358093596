import { useState, useEffect } from 'react';
import { nflAdminService } from '../api/services';
import { GAME_VARIANTS } from '../api/config';

function useNFLSCAdmin() {
  const [currentNFLSCGame, setCurrentNFLSCGame] = useState(null);
  const [newNFLSCGameName, setNewNFLSCGameName] = useState('');
  const [NFLSCSyncMessage, setNFLSCSyncMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCurrentNFLSCGame();
  }, []);

  const fetchCurrentNFLSCGame = async () => {
    try {
      setLoading(true);
      const { currentGame } = await nflAdminService.getCurrentGame(GAME_VARIANTS.NFL_SC);
      setCurrentNFLSCGame(currentGame);
    } catch (err) {
      setError('Failed to fetch current NFLSC game: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNewNFLSCGame = async () => {
    if (!newNFLSCGameName.trim()) {
      setError('Please enter a name for the new NFLSC game');
      return;
    }
    try {
      await nflAdminService.createNewGame(newNFLSCGameName, GAME_VARIANTS.NFL_SC);
      await fetchCurrentNFLSCGame();
      setNewNFLSCGameName('');
      setNFLSCSyncMessage('New NFLSC game created successfully!');
    } catch (err) {
      console.error('Error creating NFLSC game:', err);
      setError('Failed to create new NFLSC game: ' + err.message);
    }
  };

  const handleProcessNFLSCResults = async () => {
    if (!currentNFLSCGame) {
      setError('No active NFLSC game to process results for');
      return;
    }
    try {
      await nflAdminService.processResults(currentNFLSCGame.gameweek, GAME_VARIANTS.NFL_SC);
      setNFLSCSyncMessage('NFLSC results processed successfully!');
    } catch (err) {
      setError('Failed to process NFLSC results: ' + err.message);
    }
  };

  return {
    currentNFLSCGame,
    newNFLSCGameName,
    NFLSCSyncMessage,
    loading,
    error,
    setNewNFLSCGameName,
    handleCreateNewNFLSCGame,
    handleProcessNFLSCResults,
  };
}

export default useNFLSCAdmin;