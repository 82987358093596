import React from 'react';
import ReactMarkdown from 'react-markdown';

const AdSection = ({ advertisements, position }) => {
  const ads = advertisements.filter(ad => ad.position === position);

  if (ads.length === 0) return null;

  return (
    <div className={`space-y-6 ${position === 'hero-bottom' ? 'mt-8' : ''}`}>
      {ads.map((ad, index) => {
        const imageUrl = ad.image ? `${process.env.REACT_APP_STRAPI_URL}${ad.image}` : null;
        
        // Different styling for different positions
        const containerStyles = position === 'hero-bottom'
          ? 'bg-white rounded-lg shadow-sm p-6 flex flex-col md:flex-row gap-6 items-center'
          : 'bg-white rounded-lg shadow-sm p-4';

        const imageStyles = position === 'hero-bottom'
          ? 'w-full md:w-1/2 h-auto rounded'
          : 'w-full h-auto mb-4 rounded';

        return (
          <div key={index} className={containerStyles}>
            {imageUrl && (
              <img 
                src={imageUrl}
                alt={ad.title}
                className={imageStyles}
              />
            )}
            <div className={position === 'hero-bottom' ? 'md:w-1/2' : ''}>
              <h3 className="font-bold text-lg mb-2">{ad.title}</h3>
              <div className="mb-3 text-gray-600">
                <ReactMarkdown>{ad.description}</ReactMarkdown>
              </div>
              {ad.link && (
                <a 
                  href={ad.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                >
                  Learn More 
                  <svg className="w-4 h-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </a>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdSection;