import { useState, useEffect } from 'react';
import { nflAdminService } from '../api/services';
import { GAME_VARIANTS } from '../api/config';

function useNFLAdmin() {
  const [currentNflGame, setCurrentNflGame] = useState(null);
  const [newNflGameName, setNewNflGameName] = useState('');
  const [nflMatches, setNflMatches] = useState([]);
  const [selectedNflMatch, setSelectedNflMatch] = useState('');
  const [nflHomeScore, setNflHomeScore] = useState('');
  const [nflAwayScore, setNflAwayScore] = useState('');
  const [nflSyncMessage, setNflSyncMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCurrentNflGame();
  }, []);

  const fetchCurrentNflGame = async () => {
    try {
      setLoading(true);
      const { currentGame, matches } = await nflAdminService.getCurrentGame(GAME_VARIANTS.NFL_LMS);
      setCurrentNflGame(currentGame);
      setNflMatches(matches || []);
    } catch (err) {
      setError('Failed to fetch current NFL game: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNewNflGame = async () => {
    if (!newNflGameName.trim()) {
      setError('Please enter a name for the new NFL game');
      return;
    }
    try {
      await nflAdminService.createNewGame(newNflGameName, GAME_VARIANTS.NFL_LMS);
      await fetchCurrentNflGame();
      setNewNflGameName('');
      setNflSyncMessage('New NFL game created successfully!');
    } catch (err) {
      setError('Failed to create new NFL game: ' + err.message);
    }
  };

  const handleSyncNflData = async () => {
    try {
      setNflSyncMessage('Syncing NFL data...');
      const result = await nflAdminService.syncNFLData();
      setNflSyncMessage(`NFL data synced. ${result.message} ${result.details || ''}`);
      await fetchCurrentNflGame();
    } catch (err) {
      setError('Failed to sync NFL data: ' + err.message);
    }
  };

  const handleUpdateNflScore = async () => {
    try {
      await nflAdminService.updateMatchResult(
        selectedNflMatch, 
        parseInt(nflHomeScore), 
        parseInt(nflAwayScore)
      );
      setNflSyncMessage('NFL match result updated successfully!');
      await fetchCurrentNflGame();
      setNflHomeScore('');
      setNflAwayScore('');
    } catch (err) {
      setError('Failed to update NFL match result: ' + err.message);
    }
  };

  return {
    currentNflGame,
    newNflGameName,
    nflMatches,
    selectedNflMatch,
    nflHomeScore,
    nflAwayScore,
    nflSyncMessage,
    loading,
    error,
    setNewNflGameName,
    setSelectedNflMatch,
    setNflHomeScore,
    setNflAwayScore,
    handleCreateNewNflGame,
    handleSyncNflData,
    handleUpdateNflScore,
  };
}

export default useNFLAdmin;