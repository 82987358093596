import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { getAvailableGames } from '../routes';
import FEATURE_FLAGS from '../featureFlags';

const Navigation = () => {
  console.log('Navigation - FEATURE_FLAGS:', FEATURE_FLAGS);
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const games = getAvailableGames();
  console.log('Navigation - Available games:', games);

  const handleLogout = () => {
    logout();
    navigate('/');
    setIsOpen(false);
  };

  const NavItem = ({ to, onClick, children }) => (
    <NavLink
      to={to}
      className={({ isActive }) => 
        `block px-4 py-2 text-sm ${isActive ? 'text-white bg-purple-700' : 'text-purple-200 hover:bg-purple-700 hover:text-white'}`
      }
      onClick={() => {
        setIsOpen(false);
        onClick && onClick();
      }}
    >
      {children}
    </NavLink>
  );

  return (
    <nav className="bg-purple-800 text-white">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <NavLink to="/" className="text-2xl font-bold">
            {process.env.REACT_APP_SITE_NAME || 'Predictions'}
          </NavLink>
          <button 
            className="md:hidden bg-purple-700 px-3 py-2 rounded text-purple-200 hover:text-white hover:bg-purple-600"
            onClick={() => setIsOpen(!isOpen)}
          >
            Menu
          </button>
          <div className={`md:flex ${isOpen ? 'block' : 'hidden'} flex-col md:flex-row md:items-center md:space-x-4`}>
            {games.map(game => (
              <NavItem key={game.path} to={game.path}>{game.name}</NavItem>
            ))}
            {user ? (
              <>
                <NavItem to="/profile">Profile</NavItem>
                {user.isAdmin && <NavItem to="/admin">Admin</NavItem>}
                <button 
                  onClick={handleLogout} 
                  className="block w-full text-left px-4 py-2 text-sm text-purple-200 hover:bg-purple-700 hover:text-white md:w-auto"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <NavItem to="/login">Login</NavItem>
                <NavItem to="/register">Register</NavItem>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;