import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { resetPasswordLoggedIn } from '../api/auth';
import { fetchUserNflSelections } from '../api/nfl';
import { formatHandicap } from '../utils/formatHandicap';
import LoadingSpinner from '../components/common/LoadingSpinner';

function Profile() {
  const { user } = useAuth();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [archivedSelections, setArchivedSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadArchivedSelections();
  }, []);

  const loadArchivedSelections = async () => {
    try {
      setIsLoading(true);
      const selectionsData = await fetchUserNflSelections();
      
      // Sort selections by gameweek in reverse chronological order
      const sortedSelections = selectionsData
        .sort((a, b) => {
          // Extract week numbers and compare
          const weekA = parseInt(a.gameweek.match(/\d+/)?.[0] || 0);
          const weekB = parseInt(b.gameweek.match(/\d+/)?.[0] || 0);
          return weekB - weekA;
        });

      setArchivedSelections(sortedSelections);
    } catch (err) {
      console.error('Error loading archived selections:', err);
      setError('Failed to load archived selections');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    
    try {
      await resetPasswordLoggedIn(newPassword);
      setMessage('Password reset successfully');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    }
  };

  const getStatusClass = (result) => {
    switch (result) {
      case 'Win':
        return 'bg-green-50 border-green-100 text-green-700';
      case 'Loss':
        return 'bg-red-50 border-red-100 text-red-700';
      case 'Push':
        return 'bg-yellow-50 border-yellow-100 text-yellow-700';
      default:
        return 'bg-gray-50 border-gray-100 text-gray-700';
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto space-y-6">
          {/* User Info and Password Reset Section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h1 className="text-2xl font-bold mb-6">Profile</h1>
            
            <div className="mb-6 space-y-2">
              <p><span className="font-semibold">Email:</span> {user.email}</p>
              <p><span className="font-semibold">Display Name:</span> {user.displayName}</p>
            </div>
            
            <div className="border-t pt-6">
              <h2 className="text-xl font-bold mb-4">Reset Password</h2>
              <form onSubmit={handleResetPassword} className="space-y-4">
                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 mb-1">
                    New Password
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                >
                  Reset Password
                </button>
              </form>

              {message && (
                <div className={`mt-4 p-3 rounded ${
                  message.includes('successfully')
                    ? 'bg-green-50 text-green-700'
                    : 'bg-red-50 text-red-700'
                }`}>
                  {message}
                </div>
              )}
            </div>
          </div>

          {/* Archived Selections Section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-2xl font-bold mb-6">Archived NFL Super Contest Selections</h2>
            
            {error && (
              <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg mb-6">
                {error}
              </div>
            )}

            {archivedSelections.length === 0 ? (
              <p className="text-gray-600">No archived selections found.</p>
            ) : (
              <div className="space-y-6">
                {archivedSelections.map((gameweek, index) => (
                  <div key={index} className="border rounded-lg p-4">
                    <h3 className="text-lg font-semibold mb-4">{gameweek.gameweek}</h3>
                    <div className="space-y-3">
                      {gameweek.selections.map((selection, selIndex) => (
                        <div
                          key={selIndex}
                          className={`p-3 rounded-lg border ${getStatusClass(selection.result)}`}
                        >
                          <div className="flex justify-between items-center">
                            <div>
                              <span className="font-medium">{selection.selectedTeam}</span>
                              <span className="text-sm ml-2">
                                ({formatHandicap(selection.handicap)})
                              </span>
                            </div>
                            <div className="text-sm">
                              {selection.result || 'Pending'}
                            </div>
                          </div>
                          <div className="text-sm text-gray-500 mt-1">
                            {selection.match?.awayTeam} @ {selection.match?.homeTeam}
                          </div>
                        </div>
                      ))}
                      <div className="mt-2 text-right">
                        <span className="font-medium">Score: {gameweek.score || 'Pending'}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;