import React, { createContext, useState, useEffect, useContext } from 'react';
import { getCurrentUser } from './api/auth';
import { getToken, setToken, removeToken } from './utils/auth';
import { login as apiLogin } from './api/auth';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);

  const loadUser = async () => {
    const token = getToken();
    if (token) {
      try {
        const userData = await getCurrentUser();
        setUser({
          token,
          ...userData
        });
      } catch (error) {
        // Only log non-sensitive error info
        console.error('Failed to load user state');
        removeToken();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const login = async (email, password) => {
    setLoginLoading(true);
    setError(null);
    try {
      const data = await apiLogin(email, password);
      setToken(data.token);
      setUser(data.user);
      setLoginLoading(false);
      return data;
    } catch (error) {
      console.error('Authentication failed');
      setError(error.message);
      setLoginLoading(false);
      throw error;
    }
  };

  const logout = () => {
    removeToken();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, loginLoading, error, loadUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);