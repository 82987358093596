import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class NflAdminService extends BaseApi {
    async getCurrentGame() {
        try {
            const response = await this.get(`${API_ENDPOINTS.admin.nfl}/current-game`);
            console.log('NFL admin service response:', response);
            return response;
        } catch (error) {
            console.error('Error in getCurrentGame:', error);
            throw error;
        }
    }

    async createNewGame(name, gameVariant) {
        try {
            console.log('Creating new NFL game:', { name, gameVariant });
            const response = await this.post(`${API_ENDPOINTS.admin.nfl}/games`, { 
                name, 
                gameVariant 
            });
            console.log('Game creation response:', response);
            return response;
        } catch (error) {
            console.error('Error creating new NFL game:', error);
            throw error;
        }
    }

    async syncNFLData() {
        try {
            const response = await this.post(`${API_ENDPOINTS.admin.nfl}/sync`);
            console.log('NFL sync response:', response);
            return response;
        } catch (error) {
            console.error('Error syncing NFL data:', error);
            throw error;
        }
    }

    async updateMatchResult(matchId, homeScore, awayScore) {
        try {
            console.log('Updating match result:', { matchId, homeScore, awayScore });
            
            const response = await this.put(`${API_ENDPOINTS.admin.nfl}/match-result`, {
                matchId,
                homeScore,
                awayScore
            });
    
            console.log('Match result update response:', response);
            return response;
        } catch (error) {
            console.error('Error in updateMatchResult:', error);
            throw error;
        }
    }

    async processResults(gameweek, gameVariant) {
        try {
            return await this.post(`${API_ENDPOINTS.admin.nfl}/process-results/${gameweek}`, {
                gameVariant
            });
        } catch (error) {
            console.error('Error processing results:', error);
            throw error;
        }
    }

    async getWeeklyMatches() {
        try {
            return await this.get(`${API_ENDPOINTS.admin.nfl}/weekly-matches`);
        } catch (error) {
            console.error('Error getting weekly matches:', error);
            throw error;
        }
    }

    async processWeeklyResults(gameweek) {
        try {
            return await this.post(`${API_ENDPOINTS.admin.nfl}/process-weekly-results`, {
                gameweek
            });
        } catch (error) {
            console.error('Error processing weekly results:', error);
            throw error;
        }
    }

    handleRequest(promise) {
        return promise.catch(error => {
            console.error('NFL admin service error:', error);
            throw error;
        });
    }
}

// Create and export singleton instance
const nflAdminService = new NflAdminService();
export { nflAdminService };