import { footballService } from './services';

export const fetchTeams = () => footballService.fetchTeams();
export const fetchPlayers = (teamId) => {
    console.log('football.js fetchPlayers called with teamId:', teamId);
    return footballService.fetchPlayers(teamId);
};
export const fetchUserSelections = () => footballService.fetchUserSelections();
export const updateUserSelections = (selectionId, selections, gameName) => 
  footballService.updateUserSelections(selectionId, selections, gameName);
export const submitSelections = (selections, gameName) => {
    console.log('football.js submitting for game:', gameName);
    return footballService.submitSelections(selections, gameName);
  };
export const deleteUserSelection = (selectionId) => 
  footballService.deleteUserSelection(selectionId);
export const fetchAllSelections = () => footballService.fetchAllSelections();
export const fetchCurrentGameFixtures = () => footballService.fetchCurrentGameFixtures();
export const getEntryCount = () => footballService.getEntryCount();
export const getGameweekResults = () => footballService.getGameweekResults();
export const getCurrentGame = () => footballService.getCurrentGame();

// Export the service itself in case it's needed
export const service = footballService;