import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class NFLService extends BaseApi {
    constructor() {
        super();
    }

    async fetchCurrentNflGameFixtures(gameVariant = 'nfl-sc') {
        try {
            const response = await this.get(`${API_ENDPOINTS.nfl.currentGameFixtures}/${gameVariant}`);
            console.log('Fetched NFL game fixtures:', response);
            return response || { currentGame: null, matches: [] };
        } catch (error) {
            if (error.message === 'No active or upcoming game found') {
                return {
                    currentGame: null,
                    matches: []
                };
            }
            console.error('Error fetching NFL game fixtures:', error);
            throw error;
        }
    }

    async getCurrentGame(gameVariant) {
        try {
            const response = await this.get(`${API_ENDPOINTS.nfl.currentGame}/${gameVariant}`);
            return response || null;
        } catch (error) {
            console.error('Error fetching current NFL game:', error);
            throw error;
        }
    }

    async fetchMatches(gameweek) {
        try {
            const response = await this.get(`${API_ENDPOINTS.nfl.matches}/gameweek/${gameweek}`);
            return response || [];
        } catch (error) {
            console.error('Error fetching NFL matches:', error);
            return [];
        }
    }

    async submitNflSelection(gameweek, selections, selectionId = null) {
        console.log('Submitting NFL selections:', {
            gameweek,
            selectionId,
            selections: JSON.stringify(selections, null, 2)
        });
        
        try {
            const payload = {
                gameweek,
                gameVariant: 'nfl-sc',
                selections: selections.map(selection => ({
                    match: selection.matchId,
                    selectedTeam: selection.selectedTeam,
                    handicap: selection.handicap
                }))
            };
            console.log('Transformed payload:', JSON.stringify(payload, null, 2));

            if (selectionId) {
                console.log('Updating existing selection:', selectionId);
                return await this.put(`${API_ENDPOINTS.nfl.selections}/${selectionId}`, payload);
            } else {
                console.log('Creating new selection');
                return await this.post(API_ENDPOINTS.nfl.selections, payload);
            }
        } catch (error) {
            console.error('Error submitting NFL selections:', error);
            throw error;
        }
    }

    async fetchUserNflSelections() {
        try {
            const response = await this.get(`${API_ENDPOINTS.nfl.selections}/my-selections`);
            return response || [];
        } catch (error) {
            console.error('Error fetching user NFL selections:', error);
            return [];
        }
    }

    async fetchAllNflSelections(gameweek) {
        try {
            const response = await this.get(`${API_ENDPOINTS.nfl.selections}/${gameweek}`);
            return response || [];
        } catch (error) {
            console.error('Error fetching all NFL selections:', error);
            return [];
        }
    }

    async checkEligibility() {
        try {
            const response = await this.get(`${API_ENDPOINTS.nfl.base}/check-eligibility`);
            return response || { isEligible: false };
        } catch (error) {
            console.error('Error checking NFL eligibility:', error);
            return { isEligible: false };
        }
    }

    async getNflLeaderboard(variant) {
        try {
            console.log('Fetching leaderboard for variant:', variant);
            // Remove the 'nfl-' prefix here since it's already in the variant from the game data
            const response = await this.get(`${API_ENDPOINTS.nfl.leaderboard}/${variant}`);
            return response || { cumulativeLeaderboard: [], gameweekBreakdown: [], gameweeks: [] };
        } catch (error) {
            console.error('Error fetching NFL leaderboard:', error);
            throw error;
        }
    }
}

export const nflService = new NFLService();