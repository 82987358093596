const API_BASE_URL = process.env.REACT_APP_API_URL?.endsWith('/api') 
  ? process.env.REACT_APP_API_URL.slice(0, -4) 
  : process.env.REACT_APP_API_URL;

class BaseApi {
  constructor() {
    this.baseURL = `${API_BASE_URL}/api`;
    this.publicEndpoints = [
      '/football/current-game-fixtures',
      '/football/public-entry-count',
      '/auth/login',
      '/auth/register',
      '/auth/forgot-password',
      '/auth/reset-password'
    ];
  }

  async fetchWithAuth(endpoint, options = {}) {
    const headers = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store, no-cache, must-revalidate',
      'Pragma': 'no-cache',
      ...options.headers,
    };

    const isPublicEndpoint = this.publicEndpoints.some(publicPath => 
      endpoint.startsWith(publicPath)
    );
    
    if (!isPublicEndpoint) {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No auth token provided');
      }
      headers['Authorization'] = `Bearer ${token}`;
    }

    const url = `${this.baseURL}${endpoint}`;

    try {
      const response = await fetch(url, { 
        ...options, 
        headers,
        credentials: 'include'
      });

      const responseText = await response.text();

      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = JSON.parse(responseText);
          errorMessage = errorData.message || `HTTP error! status: ${response.status}`;
        } catch (e) {
          errorMessage = responseText || `HTTP error! status: ${response.status}`;
        }
        throw new Error(errorMessage);
      }

      if (responseText) {
        const parsedData = JSON.parse(responseText);
        return parsedData.data !== undefined ? parsedData.data : parsedData;
      }
      return null;
    } catch (error) {
      // Only log non-sensitive error details
      const errorMessage = endpoint.startsWith('/auth/')
        ? 'Authentication request failed'
        : `API request failed: ${error.message}`;
      console.error(errorMessage);
      throw error;
    }
  }

  async get(endpoint) {
    return this.fetchWithAuth(endpoint);
  }

  async post(endpoint, data) {
    return this.fetchWithAuth(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  async put(endpoint, data) {
    return this.fetchWithAuth(endpoint, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  async delete(endpoint) {
    return this.fetchWithAuth(endpoint, {
      method: 'DELETE',
    });
  }
}

export const API_ENDPOINTS = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    user: '/auth/user',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
  },
  football: {
    base: '/football',
    teams: '/football/teams',
    players: '/football/players',
    selections: '/football/selections',
    currentGame: '/football/current-game',
    currentGameFixtures: '/football/current-game-fixtures',
    gameweekResults: '/football/gameweek-results',
    userSelections: '/football/my-selections',
  },
  nfl: {
    base: '/nfl',
    currentGame: '/nfl/games/current',
    currentGameFixtures: '/nfl/current',
    matches: '/nfl/matches',
    selections: '/nfl/selections',
    leaderboard: '/nfl/leaderboard',
  },
  admin: {
    football: '/admin/football',        
    nfl: '/admin/nfl',
    games: '/admin/football/games',     
    sync: '/admin/football/sync',       
    currentGame: '/admin/football/current-game',
    gameweekResults: '/admin/football/gameweek-results'  
  }
};

export default BaseApi;