// src/featureFlags.js
const FEATURE_FLAGS = {
  SHOW_FOOTBALL: process.env.REACT_APP_SHOW_FOOTBALL === 'true',
  SHOW_AMERICAN_FOOTBALL: process.env.REACT_APP_SHOW_AMERICAN_FOOTBALL === 'true',
  SHOW_NFLSC: process.env.REACT_APP_SHOW_NFLSC === 'true'
};

// Keep these logs for development debugging if you want
console.log('featureFlags.js - Raw env variables:', {
  REACT_APP_SHOW_FOOTBALL: process.env.REACT_APP_SHOW_FOOTBALL,
  REACT_APP_SHOW_AMERICAN_FOOTBALL: process.env.REACT_APP_SHOW_AMERICAN_FOOTBALL,
  REACT_APP_SHOW_NFLSC: process.env.REACT_APP_SHOW_NFLSC
});

console.log('featureFlags.js - Parsed FEATURE_FLAGS:', FEATURE_FLAGS);

export default FEATURE_FLAGS;