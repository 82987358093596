import React from 'react';
import useFootballAdmin from '../../hooks/useFootballAdmin';
import Card from '../../components/common/Card';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import styles from './FootballAdmin.module.css';

function FootballAdmin() {
  const {
    currentGame,
    newGameName,
    deadline,
    syncMessage,
    loading,
    error,
    gameweekResults,
    teams,
    selectedTeam,
    players,
    scoringPlayers,
    setNewGameName,
    setDeadline,
    setSelectedTeam,
    setScoringPlayers,
    handleCreateNewGame,
    handleUpdateDeadline,
    handleSyncFootballData,
    handleUpdateScores,
    handleMarkMatchComplete,
    fetchPlayers,
  } = useFootballAdmin();

  if (loading) return <LoadingSpinner />;

  const handleTeamChange = (e) => {
    const teamId = e.target.value;
    console.log('Selected team:', teamId);
    setSelectedTeam(teamId);
    if (teamId) {
      fetchPlayers(teamId);
    }
  };

  const handlePlayerToggle = (playerId) => {
    setScoringPlayers(prev => 
      prev.includes(playerId) ? prev.filter(id => id !== playerId) : [...prev, playerId]
    );
  };

  return (
    <div className={styles.container}>
      <h2>Football Administration</h2>
      {error && <p className={styles.error}>{error}</p>}
      {syncMessage && <p className={styles.success}>{syncMessage}</p>}

      <Card>
  <h3>Current Game</h3>
  {currentGame ? (
    <div>
      <p>Game Name: {currentGame.name}</p>
      <p>Current Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
      <input
        type="datetime-local"
        value={deadline}
        onChange={(e) => setDeadline(e.target.value)}
        className={styles.input}
      />
      <button onClick={handleUpdateDeadline} className={styles.button}>
        Update Deadline
      </button>
    </div>
  ) : (
    <p>No active game. Create a new game to get started.</p>
  )}
</Card>

      <Card>
        <h3>Create New Game</h3>
        <input
          type="text"
          value={newGameName}
          onChange={(e) => setNewGameName(e.target.value)}
          placeholder="Enter new game name"
          className={styles.input}
        />
        <button onClick={handleCreateNewGame} className={styles.button}>Create New Game</button>
      </Card>

      <Card>
        <h3>Data Management</h3>
        <button onClick={handleSyncFootballData} className={styles.button}>Sync Football Data</button>
      </Card>

      <Card>
  <h3>Update Scores</h3>
  {teams && teams.length > 0 ? (
    <select 
      value={selectedTeam} 
      onChange={handleTeamChange} 
      className={styles.select}
    >
      <option value="">Select a team</option>
      {teams.map((team) => (
        <option key={team._id} value={team._id}>{team.name}</option>
      ))}
    </select>
  ) : (
    <p>No teams available</p>
  )}
  {players.length > 0 ? (
    <div className={styles.playerList}>
      <h4>Select scoring players:</h4>
      {players.map((player) => (
        <label key={player._id} className={styles.playerLabel}>
          <input
            type="checkbox"
            checked={scoringPlayers.includes(player._id)}
            onChange={() => handlePlayerToggle(player._id)}
          />
          {player.name}
        </label>
      ))}
      <button 
        onClick={handleUpdateScores}
        className={styles.button}
        disabled={!selectedTeam}
      >
        Update Scores
      </button>
    </div>
  ) : selectedTeam ? (
    <p>No players found for this team in the current gameweek.</p>
  ) : null}
</Card>

      <Card>
        <h3>Mark Match as Complete</h3>
        <button 
          onClick={() => handleMarkMatchComplete(selectedTeam)}
          className={styles.button}
          disabled={!selectedTeam}
        >
          Mark Match Complete
        </button>
      </Card>

      <Card>
        <h3>Gameweek Results</h3>
        {gameweekResults.length > 0 ? (
          <ul>
            {gameweekResults.map((match) => (
              <li key={match._id}>
                {match.homeTeam} vs {match.awayTeam} - 
                Home Score: {match.result.homeScore}, 
                Away Score: {match.result.awayScore}
              </li>
            ))}
          </ul>
        ) : (
          <p>No results available for the current gameweek.</p>
        )}
      </Card>
    </div>
  );
}

export default FootballAdmin;