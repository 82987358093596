import { nflService } from './services';

export const fetchCurrentNflGameFixtures = () => nflService.fetchCurrentNflGameFixtures();
export const submitNflSelection = (selections, gameweek) => nflService.submitNflSelection(selections, gameweek);
export const fetchUserNflSelections = () => nflService.fetchUserNflSelections();
export const fetchAllNflSelections = (gameweek) => nflService.fetchAllNflSelections(gameweek);
export const getNflLeaderboard = (variant) => nflService.getNflLeaderboard(variant);
export const updateNflSelection = (selectionId, selections, gameweek) => 
    nflService.updateNflSelection(selectionId, selections, gameweek);
export const checkEligibility = () => nflService.checkEligibility();
export const getCurrentNflGame = () => nflService.getCurrentNflGame();
export const fetchNflMatches = () => nflService.fetchNflMatches();

// Export the service itself in case it's needed
export const service = nflService;