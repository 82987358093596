import { useState, useEffect } from 'react';
import { footballAdminService } from '../api/services';

function useFootballAdmin() {
  const [currentGame, setCurrentGame] = useState(null);
  const [newGameName, setNewGameName] = useState('');
  const [deadline, setDeadline] = useState('');
  const [syncMessage, setSyncMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gameweekResults, setGameweekResults] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [players, setPlayers] = useState([]);
  const [scoringPlayers, setScoringPlayers] = useState([]);

  useEffect(() => {
    fetchCurrentGame();
  }, []);

const fetchCurrentGame = async () => {
  try {
    setLoading(true);
    setError(null);
    
    const response = await footballAdminService.getCurrentGame();
    console.log('Football admin data received:', response);
    
    if (!response || !response.currentGame) {
      setCurrentGame(null);
      setTeams([]);
      return;
    }
    
    setCurrentGame(response.currentGame);
    setTeams(response.teams || []);
    
    if (response.currentGame?.deadline) {
      setDeadline(new Date(response.currentGame.deadline).toISOString().slice(0, 16));
    }
  } catch (err) {
    console.error('Failed to fetch current game:', err);
    setError(err.message || 'Failed to fetch current game. Please ensure you are logged in as an admin.');
    setCurrentGame(null);
    setTeams([]);
  } finally {
    setLoading(false);
  }
};
  
  const fetchPlayers = async (teamId) => {
    try {
      const fetchedPlayers = await footballAdminService.getTeamPlayers(teamId);
      setPlayers(fetchedPlayers);
    } catch (err) {
      setError('Failed to fetch players: ' + err.message);
      setPlayers([]);
    }
  };

const handleCreateNewGame = async () => {
  if (!newGameName.trim()) {
    setError('Please enter a name for the new game');
    return;
  }
  try {
    setSyncMessage('Creating new game...');
    const createdGame = await footballAdminService.createNewGame(newGameName);
    if (createdGame) {
      setCurrentGame(createdGame);
      setNewGameName('');
      setSyncMessage('New game created successfully!');
      await fetchCurrentGame(); // Refresh the data
    } else {
      throw new Error('No game data received from server');
    }
  } catch (err) {
    console.error('Failed to create new game:', err);
    setError(err.message?.includes('No matches found') 
      ? 'No matches found for this gameweek. Please sync match data first.'
      : `Failed to create new game: ${err.message}`
    );
  }
};

  const handleUpdateDeadline = async () => {
    try {
      await footballAdminService.updateGameDeadline(currentGame._id, new Date(deadline));
      fetchCurrentGame();
      setSyncMessage('Deadline updated successfully!');
    } catch (err) {
      setError('Failed to update deadline: ' + err.message);
    }
  };

  const handleSyncFootballData = async () => {
    try {
      setSyncMessage('Syncing football data...');
      await footballAdminService.syncFootballData();
      setSyncMessage('Football data synced successfully!');
      fetchCurrentGame();
    } catch (err) {
      console.error('Error syncing football data:', err);
      setError('Failed to sync football data: ' + err.message);
    }
  };

  const handleUpdateScores = async () => {
    if (!selectedTeam) {
      setError('Please select a team');
      return;
    }
    try {
      const result = await footballAdminService.updateScores(selectedTeam, scoringPlayers);
      setSyncMessage(result.message || 'Scores updated successfully!');
      setScoringPlayers([]);
    } catch (err) {
      console.error('Failed to update scores:', err);
      setError('Failed to update scores: ' + err.message);
    }
  };

  const handleMarkMatchComplete = async (teamId) => {
    try {
      await footballAdminService.markMatchComplete(teamId);
      setSyncMessage('Match marked as complete!');
    } catch (err) {
      setError('Failed to mark match as complete: ' + err.message);
    }
  };

  return {
    currentGame,
    newGameName,
    deadline,
    syncMessage,
    loading,
    error,
    gameweekResults,
    teams,
    selectedTeam,
    players,
    scoringPlayers,
    setNewGameName,
    setDeadline,
    setSelectedTeam,
    setScoringPlayers,
    handleCreateNewGame,
    handleUpdateDeadline,
    handleSyncFootballData,
    handleUpdateScores,
    handleMarkMatchComplete,
    fetchPlayers,
  };
}

export default useFootballAdmin;