import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchStaticPages } from '../../api/services/strapiService';

const Footer = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMainSite = process.env.REACT_APP_SITE_TYPE === 'main';

  useEffect(() => {
    const loadPages = async () => {
      if (!isMainSite) {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_URL}/api/static-pages?populate=footer_menu`
        );
        const data = await response.json();
        setPages(data.data.filter(page => page.ShowinFooter));
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPages();
  }, [isMainSite]);

  if (loading) return null;

  // Get pages by menu
  const getPagesByMenuTitle = (menuTitle) => {
    return pages.filter(page => 
      page.footer_menu?.Title === menuTitle
    );
  };

  // Sort pages by menu position
  const aboutPages = getPagesByMenuTitle('About Us');
  const legalPages = getPagesByMenuTitle('Legal');

  // Simplified footer for non-main sites
  if (!isMainSite) {
    return (
      <footer className="bg-white border-t">
        <div className="container mx-auto px-4 py-8">
          <div className="text-center text-gray-600">
            <p>© {new Date().getFullYear()} Sports Predictions. All rights reserved.</p>
          </div>
        </div>
      </footer>
    );
  }

  // Full footer for main site
  return (
    <footer className="bg-white border-t">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* About Section */}
          <div>
            <h3 className="font-semibold text-gray-900 mb-4">About</h3>
            <ul className="space-y-2">
              {aboutPages.map(page => (
                <li key={page.id}>
                  <Link
                    to={`/${page.slug}`}
                    className="text-gray-600 hover:text-purple-600 transition-colors"
                  >
                    {page.Title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Games Section */}
          <div>
            <h3 className="font-semibold text-gray-900 mb-4">Games</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/football"
                  className="text-gray-600 hover:text-purple-600 transition-colors"
                >
                  Football - 5 Scorers
                </Link>
              </li>
              {isMainSite && (
                <li>
                  <Link
                    to="/nfl-sc"
                    className="text-gray-600 hover:text-purple-600 transition-colors"
                  >
                    NFL Super Contest
                  </Link>
                </li>
              )}
            </ul>
          </div>

          {/* Legal Section */}
          <div>
            <h3 className="font-semibold text-gray-900 mb-4">Legal</h3>
            <ul className="space-y-2">
              {legalPages.map(page => (
                <li key={page.id}>
                  <Link
                    to={`/${page.slug}`}
                    className="text-gray-600 hover:text-purple-600 transition-colors"
                  >
                    {page.Title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t text-center text-gray-600">
          <p>© {new Date().getFullYear()} Sports Predictions. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;