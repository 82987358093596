// src/components/home/GameSection/index.js
import React from 'react';
import moment from 'moment-timezone';
import FEATURE_FLAGS from '../../../featureFlags';
import GameCard from '../../common/GameCard';

const GameSection = ({ currentGame, isMainSite }) => {
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    const date = moment.utc(dateTimeString);
    const ukTime = date.tz('Europe/London');
    return ukTime.format('dddd, h:mm a z');
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {FEATURE_FLAGS.SHOW_FOOTBALL && (
        <div className="w-full"> {/* Added wrapper div */}
          <GameCard
            title="Football - 5 Scorers"
            deadline={currentGame ? formatDateTime(currentGame.deadline) : 'No active game'}
            links={[{ to: "/football", text: "Play Game" }]}
            className="h-full" // Added height
          />
        </div>
      )}
      {isMainSite && FEATURE_FLAGS.SHOW_NFLSC && (
        <div className="w-full"> {/* Added wrapper div */}
          <GameCard
            title="NFL Super Contest"
            links={[{ to: "/nfl-sc", text: "Play Game" }]}
            className="h-full" // Added height
          />
        </div>
      )}
    </div>
  );
};

export default GameSection;