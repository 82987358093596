import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class FootballAdminService extends BaseApi {
    async getCurrentGame() {
        try {
            const response = await this.get(`${API_ENDPOINTS.admin.football}/current-game`);
            console.log('Football admin service response:', response); // Debug log
            return response;
        } catch (error) {
            console.error('Error in getCurrentGame:', error);
            throw error;
        }
    }

    async createNewGame(name) {
        try {
          console.log('Creating new game:', name);
          const response = await this.post(`${API_ENDPOINTS.admin.football}/games`, { 
            name 
          });
          console.log('Game creation response:', response);
          return response;
        } catch (error) {
          console.error('Error creating new game:', error);
          throw error;
        }
      }

    async updateGameDeadline(gameId, deadline) {
        return this.put(`${API_ENDPOINTS.admin.football}/games/${gameId}/deadline`, { 
            deadline 
        });
    }

    async syncFootballData() {
        return this.post(`${API_ENDPOINTS.admin.football}/sync`);
    }

    async getTeamPlayers(teamId) {
        return this.get(`${API_ENDPOINTS.admin.football}/team-players/${teamId}`);
    }

    async updateScores(teamId, scoringPlayers) {
        return this.post(`${API_ENDPOINTS.admin.football}/update-scores`, {
            teamId,
            scoringPlayers
        });
    }

    async markMatchComplete(teamId) {
        return this.post(`${API_ENDPOINTS.admin.football}/mark-match-complete`, { 
            teamId 
        });
    }

    handleRequest(promise) {
        return promise.catch(error => {
            console.error('Admin service error:', error);
            throw error;
        });
    }

    async getGameweekResults() {
        try {
            return this.get(`${API_ENDPOINTS.admin.football}/gameweek-results`);
        } catch (error) {
            console.error('Error getting gameweek results:', error);
            throw error;
        }
    }
}

// Create and export singleton instance
const footballAdminService = new FootballAdminService();
export { footballAdminService };