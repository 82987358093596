import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import { routes } from './routes';
import FEATURE_FLAGS from './featureFlags';

console.log('Environment:', process.env.NODE_ENV);
console.log('API URL:', process.env.REACT_APP_API_URL);
console.log('App.js - FEATURE_FLAGS:', FEATURE_FLAGS);
console.log('REACT_APP_SITE_NAME:', process.env.REACT_APP_SITE_NAME);

function App() {
  console.log('Rendering App with Feature Flags:', FEATURE_FLAGS);

  return (
    <Router>
      <Layout>
        <Routes>
          {routes
            .filter(route => 
              route.alwaysShow || 
              (route.feature && FEATURE_FLAGS[route.feature])
            )
            .map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;