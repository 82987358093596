import baseApi from '../base/baseApi';

const STRAPI_URL = process.env.REACT_APP_STRAPI_URL || 'http://localhost:1337';

export const fetchPage = async (pageType) => {
  try {
    const url = `${STRAPI_URL}/api/pages?filters[pageType][$eq]=${pageType}&populate=*`;
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error('Failed to fetch page content');
    }

    const data = await response.json();
    console.log('Raw page data:', data);  // Debug log
    
    if (data.data && data.data.length > 0) {
      const page = data.data[0];
      
      // Extract hero content from array
      const heroSection = page.hero && page.hero.length > 0 ? page.hero[0] : null;

      return {
        hero: {
          title: heroSection?.title || '',
          description: heroSection?.description?.[0]?.children?.[0]?.text || ''
        },
        mainContent: page.mainContent?.[0]?.children?.[0]?.text || '',
        sidebarContent: page.sidebarContent?.[0]?.children?.[0]?.text || '',
        seoTitle: page.seoTitle,
        seoDescription: page.seoDescription
      };
    }
    
    return null;
  } catch (error) {
    console.error('Error in fetchPage:', error);
    return null;
  }
};

export const fetchAdvertisements = async (placement) => {
  try {
    let url = `${process.env.REACT_APP_STRAPI_URL}/api/advertisements?populate=*`;
    
    // Add filters
    const filters = [];
    if (placement) {
      filters.push(`filters[placement][$eq]=${placement}`);
    }
    filters.push('filters[isActive][$eq]=true');
    
    // Add date filters
    const now = new Date().toISOString();
    filters.push(`filters[startDate][$lte]=${now}`);
    filters.push(`filters[endDate][$gte]=${now}`);
    
    if (filters.length) {
      url += `&${filters.join('&')}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch advertisements');
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching advertisements:', error);
    return [];
  }
};

export const fetchStaticPages = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/static-pages`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch static pages');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching static pages:', error);
    return { data: [] };
  }
};

export const fetchStaticPage = async (slug) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/static-pages?filters[slug][$eq]=${slug}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch page');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching page:', error);
    throw error;
  }
};

export const fetchFooterMenus = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/footer-menus?sort=displayOrder:asc`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch footer menus');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching footer menus:', error);
    return { data: [] };
  }
};