import React, { useState, useEffect } from 'react';
import { Calendar, Edit, ChevronDown, Users, Clock, Loader2, Trophy } from 'lucide-react';
import { useAuth } from '../../AuthContext';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { fetchCurrentGameFixtures, fetchUserSelections, fetchAllSelections, fetchTeams, fetchPlayers, submitSelections, updateUserSelections } from '../../api/football';

const useCountdown = (deadline) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(deadline));

  function calculateTimeLeft(deadline) {
    if (!deadline) return null;
    
    const difference = new Date(deadline) - new Date();
    if (difference <= 0) return null;

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60)
    };
  }

  useEffect(() => {
    if (!deadline) return;

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(deadline);
      if (newTimeLeft === null) {
        clearInterval(timer);
      }
      setTimeLeft(newTimeLeft);
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [deadline]);

  return timeLeft;
};

const Football5sPage = () => {
  const [currentGame, setCurrentGame] = useState(null);
  const [fixtures, setFixtures] = useState([]);
  const [userSelections, setUserSelections] = useState(null);
  const [allSelections, setAllSelections] = useState([]);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState({});
  const [selections, setSelections] = useState(Array(5).fill({ 
    teamId: '',
    team: '',
    playerId: '',
    player: '' 
  }));
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const { user } = useAuth();
  const [loadingTeam, setLoadingTeam] = useState(null);
  const timeLeft = useCountdown(currentGame?.deadline);
  const [selectionsCount, setSelectionsCount] = useState(0);
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);  

  useEffect(() => {
    console.log('State Check:', {
      currentGame,
      isDeadlinePassed,
      allSelections: allSelections.length,
      user
    });
  }, [currentGame, isDeadlinePassed, allSelections, user]);

  useEffect(() => {
    const loadData = async () => {
      console.log('Loading data...');
      try {
        const gameData = await fetchCurrentGameFixtures();
        console.log('Game data received:', gameData);
        setCurrentGame(gameData.currentGame);
        setFixtures(gameData.matches || []);
      
        if (gameData.currentGame) {
          const deadline = new Date(gameData.currentGame.deadline);
          const now = new Date();
          console.log('Deadline check:', { deadline, now, isPassed: now > deadline });
          
          if (now > deadline) {
            console.log('Fetching all selections...');
            const allSelectionsData = await fetchAllSelections();
            console.log('All selections received:', allSelectionsData);
            setAllSelections(allSelectionsData.selections);
            setSelectionsCount(allSelectionsData.totalCount);
          }
        }
  
        if (user && gameData.currentGame) {
          try {
            console.log('Fetching user-specific data...');
            const [teamsData, userSelectionsData, allSelectionsData] = await Promise.all([
              fetchTeams(),
              fetchUserSelections(),
              fetchAllSelections()
            ]);
  
            console.log('User data received:', {
              teams: teamsData.length,
              userSelections: userSelectionsData,
              allSelections: allSelectionsData
            });
  
            setTeams(teamsData);
            setAllSelections(allSelectionsData.selections);
            setSelectionsCount(allSelectionsData.totalCount);
  
            if (userSelectionsData && userSelectionsData.length > 0) {
              setUserSelections(userSelectionsData[0]);
              setSelections(userSelectionsData[0].selections);
            } else {
              setUserSelections(null);
              setSelections(Array(5).fill({ 
                teamId: '',
                team: '',
                playerId: '',
                player: '' 
              }));
            }
          } catch (err) {
            console.error('Error loading user data:', err);
            setError('Failed to load selection data. Please try again later.');
          }
        }
      } catch (err) {
        console.error('Error in loadData:', err);
        if (err.message !== 'No active game found') {
          setError('Failed to load game data. Please try again later.');
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    loadData();
  }, [user]);

  useEffect(() => {
    let interval;
    
    if (isDeadlinePassed && currentGame) {
      console.log('Setting up selection refresh interval');
      interval = setInterval(async () => {
        try {
          const allSelectionsData = await fetchAllSelections();
          console.log('Interval refresh - selections:', allSelectionsData);
          setAllSelections(allSelectionsData.selections);
          setSelectionsCount(allSelectionsData.totalCount);
        } catch (error) {
          console.error('Error refreshing selections:', error);
        }
      }, 300000); // Every 5 minutes
    }
  
    return () => {
      if (interval) {
        console.log('Clearing selection refresh interval');
        clearInterval(interval);
      }
    };
  }, [isDeadlinePassed, currentGame]);

useEffect(() => {
  if (currentGame?.deadline) {
    const checkDeadline = () => {
      const isPassed = new Date() > new Date(currentGame.deadline);
      setIsDeadlinePassed(isPassed);
      
      // If deadline is passed, ensure we have all selections
      if (isPassed && currentGame) {
        console.log('Deadline passed, fetching selections');
        fetchAllSelections()
          .then(data => {
            console.log('Fetched selections after deadline check:', data);
            setAllSelections(data.selections);
            setSelectionsCount(data.totalCount);
          })
          .catch(err => console.error('Error fetching selections:', err));
      }
    };

    // Initial check
    checkDeadline();

    // Check every minute
    const interval = setInterval(checkDeadline, 60000);

    return () => clearInterval(interval);
  }
}, [currentGame]);

const handleTeamChange = async (index, teamId) => {
  try {
    setLoadingTeam(index);
    console.log('Team selected:', teamId);
    
    const selectedTeam = teams.find(t => t._id === teamId);
    if (!selectedTeam) {
      console.error('Team not found:', teamId);
      return;
    }

    const newSelections = [...selections];
    const currentSelection = newSelections[index];
    
    newSelections[index] = { 
      teamId: selectedTeam._id,
      team: selectedTeam.name,
      playerId: currentSelection.team === selectedTeam.name ? currentSelection.playerId : '',
      player: currentSelection.team === selectedTeam.name ? currentSelection.player : ''
    };
    setSelections(newSelections);

    if (!players[selectedTeam.name]) {
      const teamPlayers = await fetchPlayers(teamId);
      setPlayers(prevPlayers => ({
        ...prevPlayers,
        [selectedTeam.name]: teamPlayers
      }));
    }
  } catch (error) {
    console.error('Error in handleTeamChange:', error);
  } finally {
    setLoadingTeam(null);
  }
};

const handlePlayerChange = (index, playerId) => {
  const selectedTeam = selections[index].team;
  const selectedPlayer = players[selectedTeam]?.find(p => p._id === playerId);
  if (!selectedPlayer) return;

  // Check for duplicate selection
  const isDuplicate = selections.some((selection, i) => 
      i !== index && selection.playerId === playerId
  );

  if (isDuplicate) {
      setError('This player has already been selected. Please choose another player.');
      return;
  }

  const newSelections = [...selections];
  newSelections[index] = { 
      ...newSelections[index],
      playerId: selectedPlayer._id,
      player: selectedPlayer.name
  };
  setSelections(newSelections);
  setError(null);
};

const handleSubmit = async (e) => {
  e.preventDefault();
  console.log('Current game:', currentGame); // Debug log
  
  if (selections.some(s => !s.teamId || !s.playerId)) {
    setError('Please make all 5 selections before submitting.');
    return;
  }
  if (!currentGame) {
    setError('No active game found. Please refresh the page and try again.');
    return;
  }
  try {
    let updatedSelections;
    if (userSelections && userSelections._id) {
      updatedSelections = await updateUserSelections(
        userSelections._id, 
        selections,
        currentGame.name
      );
      setSuccessMessage('Selections updated successfully!');
    } else {
      updatedSelections = await submitSelections(
        selections,
        currentGame.name
      );
      setSuccessMessage('Selections submitted successfully!');
    }

    setUserSelections(updatedSelections);
    setIsEditing(false);
    setError(null);
  } catch (err) {
    console.error('Error submitting selections:', err);
    setError(`Failed to submit selections: ${err.message}`);
  }
};

const handleEdit = async () => {
  setSuccessMessage(null); 
  try {
      console.log('Entering edit mode with selections:', userSelections);
      
      const newPlayersState = {};
      
      // Load players sequentially to avoid any race conditions
      for (const selection of userSelections.selections) {
          const selectedTeam = teams.find(t => t.name === selection.team);
          if (selectedTeam) {
              console.log('Fetching players for team:', selectedTeam.name, 'ID:', selectedTeam._id);
              const playersData = await fetchPlayers(selectedTeam._id);
              console.log('Received players for team:', selectedTeam.name, playersData);
              newPlayersState[selection.team] = playersData;
          }
      }

      console.log('Final players state:', newPlayersState);
      setPlayers(newPlayersState);

      // Format selections after we have all the players
      const formattedSelections = userSelections.selections.map(selection => {
          const team = teams.find(t => t.name === selection.team);
          const teamPlayers = newPlayersState[selection.team] || [];
          const player = teamPlayers.find(p => p.name === selection.player);
          
          console.log('Formatting selection:', {
              team: selection.team,
              availablePlayers: teamPlayers.length,
              foundPlayer: player ? player.name : 'not found'
          });

          return {
              teamId: team?._id || '',
              team: selection.team,
              playerId: player?._id || '',
              player: selection.player
          };
      });

      console.log('Final formatted selections:', formattedSelections);
      setSelections(formattedSelections);
      setIsEditing(true);
  } catch (error) {
      console.error('Error preparing edit mode:', error);
      setError('Failed to load player data for editing');
  }
};

  const calculateUserScore = (selections) => {
    let scored = 0;
    let notScored = 0;
    let pending = 0;
  
    selections.forEach(selection => {
      if (selection.hasScored === "true" || selection.hasScored === true) scored++;
      else if (selection.hasScored === "false" || selection.hasScored === false) notScored++;
      else pending++;
    });
  
    return { scored, notScored, pending, potentialMax: scored + pending };
  };

  const sortSelections = (selections) => {
    return [...selections].sort((a, b) => {
      const scoreA = calculateUserScore(a.selections);
      const scoreB = calculateUserScore(b.selections);
  
      // First sort by users who have no incorrect picks
      const aHasIncorrect = scoreA.notScored > 0;
      const bHasIncorrect = scoreB.notScored > 0;
      if (aHasIncorrect !== bHasIncorrect) {
        return aHasIncorrect ? 1 : -1;
      }
  
      // Then by number of correct picks
      if (scoreA.scored !== scoreB.scored) {
        return scoreB.scored - scoreA.scored;
      }
  
      // Then by potential maximum achievable (correct + pending)
      if (scoreA.potentialMax !== scoreB.potentialMax) {
        return scoreB.potentialMax - scoreA.potentialMax;
      }
  
      // Finally by username
      return (a.user?.displayName || '').localeCompare(b.user?.displayName || '');
    });
  };

  const renderHeader = () => (
    <div className="bg-emerald-600 px-4 py-3">
      <div className="flex justify-between items-start">
        <div>
          <div className="flex items-center gap-2">
            <h1 className="text-xl font-bold text-white">Football 5s</h1>
            <div className="flex items-center text-white">
              <Trophy className="w-4 h-4 mr-1" />
              <span className="font-medium">€825</span>
            </div>
          </div>
          <div className="flex items-center gap-2 text-sm text-white/90 mt-1">
            <span>{currentGame?.name}</span>
            <span className="text-white/60">•</span>
            <span className="flex items-center">
              <Calendar className="w-3.5 h-3.5 mr-1" />
              {new Date(currentGame?.deadline).toLocaleString(undefined, {
                day: 'numeric',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
            <span className="text-white/60">•</span>
            <span className="flex items-center">
              <Users className="w-3.5 h-3.5 mr-1" />
              {selectionsCount} Players
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderFixtures = () => (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="border-b border-gray-200">
        <h2 className="text-lg font-bold px-4 py-3">Gameweek Fixtures</h2>
      </div>
  
      {/* Desktop version */}
      <div className="hidden md:block">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-100 bg-gray-50">
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Home</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Away</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Kick-off</th>
            </tr>
          </thead>
          <tbody>
            {fixtures.map((match) => (
              <tr key={match._id} className="border-b border-gray-100">
                <td className="py-3 px-4 font-medium">{match.homeTeam}</td>
                <td className="py-3 px-4 font-medium">{match.awayTeam}</td>
                <td className="py-3 px-4 text-gray-600">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-2 text-gray-400" />
                    {new Date(match.kickoffTime).toLocaleString(undefined, {
                      weekday: 'short',
                      day: 'numeric',
                      month: 'short',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
      {/* Mobile version */}
      <div className="md:hidden">
        <div className="divide-y divide-gray-100">
          {fixtures.map((match) => (
            <div key={match._id} className="px-4 py-3">
              <div className="grid grid-cols-7 items-center gap-2">
                <div className="col-span-3 text-right font-medium">{match.homeTeam}</div>
                <div className="col-span-1 text-xs text-gray-400 text-center">vs</div>
                <div className="col-span-3 font-medium">{match.awayTeam}</div>
              </div>
              <div className="flex items-center justify-center mt-1">
                <Clock className="w-3 h-3 mr-1 text-gray-400" />
                <span className="text-xs text-gray-500">
                  {new Date(match.kickoffTime).toLocaleString(undefined, {
                    weekday: 'short',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

const renderSelectionForm = () => (
  <div className="bg-white rounded-lg shadow-sm p-6 transition-all duration-300 ease-in-out">
    <h2 className="text-2xl font-bold mb-6">Make Selections</h2>
    <form onSubmit={handleSubmit} className="space-y-4">
      {selections.map((selection, index) => {
        const availablePlayers = players[selection.team] || [];
        const isLoading = loadingTeam === index;
        
        return (
          <div 
            key={index}
            className="grid grid-cols-12 gap-4 items-center bg-gray-50 p-4 rounded-lg transition-all duration-300 ease-in-out hover:bg-gray-100"
          >
            <span className="col-span-1 font-bold text-lg text-gray-400">{index + 1}.</span>
            <div className="col-span-11 sm:col-span-5 relative">
              <select
                value={selection.teamId || ''}
                onChange={(e) => handleTeamChange(index, e.target.value)}
                className={`w-full p-3 pr-10 border rounded-lg appearance-none bg-white cursor-pointer 
                  ${error ? 'border-red-300 focus:ring-red-500/20 focus:border-red-500' : 
                    'hover:border-emerald-500 focus:ring-emerald-500/20 focus:border-emerald-500'}
                  transition-all`}
              >
                <option value="">Select Team</option>
                {teams.map((team) => (
                  <option key={team._id} value={team._id}>{team.name}</option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" size={20} />
            </div>
            <div className="col-span-11 col-start-2 sm:col-span-6 sm:col-start-7 relative">
              {isLoading ? (
                <div className="w-full p-3 border rounded-lg bg-gray-50">
                  <Loader2 className="w-5 h-5 animate-spin text-gray-400 mx-auto" />
                </div>
              ) : (
                <select
                  value={selection.playerId || ''}
                  onChange={(e) => handlePlayerChange(index, e.target.value)}
                  className={`w-full p-3 pr-10 border rounded-lg appearance-none bg-white cursor-pointer
                    ${error ? 'border-red-300 focus:ring-red-500/20 focus:border-red-500' : 
                      'hover:border-emerald-500 focus:ring-emerald-500/20 focus:border-emerald-500'}
                    transition-all disabled:opacity-50 disabled:cursor-not-allowed`}
                  disabled={!selection.team}
                >
                  <option value="">Select Player</option>
                  {availablePlayers.map((player) => (
                    <option key={player._id} value={player._id}>{player.name}</option>
                  ))}
                </select>
              )}
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" size={20} />
            </div>
          </div>
        );
      })}
      <button type="submit" className="w-full bg-emerald-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500/20 transition-all duration-300">
        {userSelections ? 'Update Selections' : 'Submit Selections'}
      </button>
    </form>
  </div>
);

const renderUserSelections = () => (
  <div className="bg-white rounded-lg shadow-sm p-6 transition-all duration-300 ease-in-out">
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-2xl font-bold">My Selections</h2>
      {!isDeadlinePassed && (
        <button
          onClick={handleEdit}
          className="flex items-center px-4 py-2 text-sm font-medium text-emerald-600 border border-emerald-600 rounded-lg hover:bg-emerald-50 transition-colors duration-300"
        >
          <Edit className="w-4 h-4 mr-2" />
          Edit Selections
        </button>
      )}
    </div>
    <div className="space-y-3">
      {userSelections.selections.map((selection, index) => (
        <div
          key={index}
          className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-all duration-300 ease-in-out"
        >
          <div className="flex items-center">
            <span className="font-bold text-lg text-gray-400 w-8">{index + 1}.</span>
            <span className="font-medium">{selection.player}</span>
          </div>
          <span className="text-gray-500">({selection.team})</span>
        </div>
      ))}
    </div>
  </div>
);

const renderAllSelections = () => {
  const getStatusStyles = (selection) => {
    if (selection.hasScored === "true") {
      return 'bg-green-50 text-green-700 border-green-100';
    }
    if (selection.hasScored === "false") {
      return 'bg-red-50 text-red-700 border-red-100';
    }
    return 'bg-gray-50 text-gray-600 border-gray-100';
  };

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="border-b border-gray-200">
        <h2 className="text-lg font-bold px-4 py-3">All Selections</h2>
      </div>

      {/* Desktop version */}
      <div className="hidden md:block">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-100 bg-gray-50">
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">User</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Selection 1</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Selection 2</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Selection 3</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Selection 4</th>
              <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">Selection 5</th>
            </tr>
          </thead>
          <tbody>
            {sortSelections(allSelections).map((userSelection, index) => (
              <tr key={userSelection._id || index} className="border-b border-gray-100">
                <td className="py-3 px-4">
                  <span className="font-medium">{userSelection.user.displayName}</span>
                </td>
                {userSelection.selections.map((selection, selIndex) => (
                  <td key={selIndex} className="py-2 px-4">
                    <div className={`rounded border p-2 ${getStatusStyles(selection)}`}>
                      <div className="font-medium text-sm">
                        {selection.player}
                      </div>
                      <div className="text-xs mt-0.5 opacity-75">
                        {selection.team}
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile version */}
      <div className="md:hidden divide-y divide-gray-200">
        {sortSelections(allSelections).map((userSelection, index) => (
          <div key={userSelection._id || index} className="px-4 py-3">
            <div className="font-medium mb-2">
              {userSelection.user.displayName}
            </div>
            <div className="grid grid-cols-3 gap-1.5">
              {userSelection.selections.map((selection, selIndex) => (
                <div key={selIndex} 
                  className={`rounded border px-2 py-1.5 text-center ${getStatusStyles(selection)}`}>
                  <div className="text-sm font-medium">
                    {selection.player}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

  if (isLoading) return (
    <div className="min-h-screen flex items-center justify-center">
      <LoadingSpinner />
    </div>
  );

return (
  <div className="min-h-screen bg-gray-50">
    {renderHeader()}
    
    <main className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto space-y-6">
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}
        
        {successMessage && (
          <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg">
            {successMessage}
          </div>
        )}

        {!currentGame ? (
          <div className="bg-white rounded-lg shadow-sm p-6">
            <p className="text-lg text-gray-600">
              There is no active gameweek at the moment. Please check back later.
            </p>
          </div>
        ) : (
          <>
            {!isDeadlinePassed ? (
              user ? (
                userSelections && !isEditing ? renderUserSelections() : renderSelectionForm()
              ) : (
                <div className="bg-white rounded-lg shadow-sm p-6">
                  <p className="text-lg">
                    Please <a href="/login" className="text-emerald-600 hover:underline">log in</a> to make or view your selections.
                  </p>
                </div>
              )
            ) : (
              allSelections.length > 0 && renderAllSelections()
            )}
          </>
        )}

        {/* Add Fixtures table only if there's an active game */}
        {currentGame && renderFixtures()}
      </div>
    </main>
  </div>
  );
}

export default Football5sPage;