import React from 'react';
import useNFLAdmin from '../../hooks/useNFLAdmin';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from './NFLAdmin.module.css';

function NFLAdmin() {
  const {
    currentNflGame,
    newNflGameName,
    nflMatches,
    selectedNflMatch,
    nflHomeScore,
    nflAwayScore,
    nflSyncMessage,
    loading,
    error,
    setNewNflGameName,
    setSelectedNflMatch,
    setNflHomeScore,
    setNflAwayScore,
    handleCreateNewNflGame,
    handleSyncNflData,
    handleUpdateNflScore,
  } = useNFLAdmin();

  if (loading) return <LoadingSpinner />;

  return (
    <div className={styles.section}>
      <h2>NFL Administration</h2>
      {error && <p className={styles.error}>{error}</p>}
      {nflSyncMessage && <p className={styles.success}>{nflSyncMessage}</p>}

      <div className={styles.subsection}>
        <h3>Current NFL Game</h3>
        {currentNflGame ? (
          <div>
            <p>Game Name: {currentNflGame.name}</p>
            <p>Current Deadline: {new Date(currentNflGame.deadline).toLocaleString()}</p>
          </div>
        ) : (
          <p>No active NFL game.</p>
        )}
      </div>

      <div className={styles.subsection}>
        <h3>Create New NFL Game</h3>
        <input
          type="text"
          value={newNflGameName}
          onChange={(e) => setNewNflGameName(e.target.value)}
          placeholder="Enter new NFL game name"
          className={styles.input}
        />
        <button onClick={handleCreateNewNflGame} className={styles.button}>Create New NFL Game</button>
      </div>

      <div className={styles.subsection}>
        <h3>Data Management</h3>
        <button onClick={handleSyncNflData} className={styles.button}>Sync NFL Data</button>
      </div>

      <div className={styles.subsection}>
        <h3>Update NFL Match Result</h3>
        <select 
          value={selectedNflMatch} 
          onChange={(e) => setSelectedNflMatch(e.target.value)}
          className={styles.select}
        >
          <option value="">Select a match</option>
          {nflMatches.map(match => (
            <option key={match._id} value={match._id}>
              {match.awayTeam} @ {match.homeTeam} ({new Date(match.kickoffTime).toLocaleString()})
            </option>
          ))}
        </select>
        {selectedNflMatch && (
          <div>
            <input
              type="number"
              value={nflHomeScore}
              onChange={(e) => setNflHomeScore(e.target.value)}
              placeholder="Home Score"
              className={styles.input}
            />
            <input
              type="number"
              value={nflAwayScore}
              onChange={(e) => setNflAwayScore(e.target.value)}
              placeholder="Away Score"
              className={styles.input}
            />
            <button onClick={handleUpdateNflScore} className={styles.button}>
              Update NFL Score
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default NFLAdmin;