import React from 'react';
import FootballAdmin from '../components/admin/FootballAdmin';
import NFLAdmin from '../components/admin/NFLAdmin';
import NFLSCAdmin from '../components/admin/NFLSCAdmin';
import ErrorBoundary from '../components/common/ErrorBoundary';

function Admin() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Admin Dashboard</h1>
      <ErrorBoundary>
        <div className="mb-8">
          <FootballAdmin />
        </div>
      </ErrorBoundary>
      <ErrorBoundary>
        <div className="mb-8">
          <NFLAdmin />
        </div>
      </ErrorBoundary>
      <ErrorBoundary>
        <div className="mb-8">
          <NFLSCAdmin />
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default Admin;