import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class FootballService extends BaseApi {
    constructor() {
        super();
    }

    async fetchCurrentGameFixtures() {
        try {
          const response = await this.get(API_ENDPOINTS.football.currentGameFixtures);
          return response || { currentGame: null, matches: [] };
        } catch (error) {
          if (error.message === 'No active game found') {
            return {
              currentGame: null,
              matches: []
            };
          }
          throw error;
        }
      }

    async getPublicEntryCount() {
        return this.get(API_ENDPOINTS.football.publicEntryCount);
    }

    async fetchTeams() {
        return this.get(API_ENDPOINTS.football.teams);
    }
    
async fetchPlayers(teamId) {
    console.log('FootballService.fetchPlayers called with teamId:', teamId);
    if (!teamId) {
        console.warn('No teamId provided to fetchPlayers');
        return [];
    }

    try {
        const endpoint = `${API_ENDPOINTS.football.players}?teamId=${encodeURIComponent(teamId)}`;
        console.log('Fetching players from endpoint:', endpoint);
        const response = await this.get(endpoint);
        
        // Add this log to see what we're getting back
        console.log('Raw player response:', response);
        
        // Transform and return the data
        if (response.success && response.data) {
            return response.data;
        } else if (Array.isArray(response)) {
            return response;
        }
        return [];
    } catch (error) {
        console.error('Error fetching players:', error);
        return [];
    }
}

    async getCurrentGame() {
        return this.get(API_ENDPOINTS.football.currentGame);
    }

async fetchUserSelections() {
    try {
        const response = await this.get(`${API_ENDPOINTS.football.selections}/my-selections`);
        return response || [];
    } catch (error) {
        console.error('Error fetching user selections:', error);
        return [];
    }
}

    async submitSelections(selections, gameName) {
        console.log('Football service receiving selections:', selections);
        console.log('Football service receiving gameName:', gameName);
        
        if (!gameName) {
            console.error('No gameName provided to submitSelections');
            throw new Error('Game name is required');
        }

        const payload = {
            selections: selections.map(selection => ({
                team: selection.teamId,
                player: selection.playerId
            })),
            gameName: gameName
        };

        console.log('Sending payload to backend:', payload);
        return this.post(API_ENDPOINTS.football.selections, payload);
    }

    async updateUserSelections(selectionId, selections, gameName) {
        console.log('Updating selections with:', { selectionId, selections, gameName });
        
        if (!gameName) {
            throw new Error('Game name is required');
        }

        const payload = {
            selections: selections.map(selection => ({
                team: selection.teamId,
                player: selection.playerId
            })),
            gameName: gameName
        };

        console.log('Sending payload:', payload);
        return this.put(`${API_ENDPOINTS.football.selections}/${selectionId}`, payload);
    }

    async deleteUserSelection(selectionId) {
        return this.delete(`${API_ENDPOINTS.football.selections}/${selectionId}`);
    }

    async fetchAllSelections() {
        return this.get(API_ENDPOINTS.football.selections);
    }

    async getGameweekResults() {
        return this.get(API_ENDPOINTS.football.gameweekResults);
    }

    async getEntryCount() {
        try {
            const response = await this.get(API_ENDPOINTS.football.publicEntryCount);
            return response.count;
        } catch (error) {
            console.error('Error fetching entry count:', error);
            return 0;
        }
    }
}

export const footballService = new FootballService();