import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, Users, Trophy } from 'lucide-react';
import { footballService } from '../../api/services';

const GameCard = ({ 
  style = 'detailed', 
  showPrizePool = true, 
  primaryColor, 
  game,
  // Keep existing props for backward compatibility
  title, 
  deadline, 
  links 
}) => {
  const [entryCount, setEntryCount] = useState(0);
  const navigate = useNavigate();
  
  // Use either new game prop or existing title prop
  const gameTitle = game?.title || title;
  
  const getGameType = () => {
    if (gameTitle.includes('Football - 5')) return 'football';
    if (gameTitle.includes('Last Man')) return 'nfl-lms';
    if (gameTitle.includes('Super Contest')) return 'nfl-sc';
    return 'default';
  };

  useEffect(() => {
    if (getGameType() === 'football') {
      const loadEntryCount = async () => {
        try {
          const count = await footballService.getEntryCount();
          setEntryCount(count);
        } catch (error) {
          console.error('Error fetching entry count:', error);
        }
      };
      loadEntryCount();
    }
  }, [gameTitle]);

  const getBannerStyle = () => {
    if (primaryColor) return `bg-gradient-to-br from-${primaryColor} to-${primaryColor}`;
    
    switch (getGameType()) {
      case 'football':
        return 'bg-gradient-to-br from-emerald-600 to-emerald-800';
      case 'nfl-lms':
        return 'bg-gradient-to-br from-blue-600 to-blue-800';
      case 'nfl-sc':
        return 'bg-gradient-to-br from-violet-600 to-violet-800';
      default:
        return 'bg-gradient-to-br from-gray-600 to-gray-800';
    }
  };

  const getPrizePool = () => {
    if (game?.prizePool) return game.prizePool;
    
    switch (getGameType()) {
      case 'football':
        return '€825';
      case 'nfl-lms':
        return '£250';
      case 'nfl-sc':
        return '£500';
      default:
        return null;
    }
  };

  // Keep your existing pattern overlay
  const getPatternOverlay = () => (
    <div className="absolute inset-0 opacity-10">
      <svg width="100%" height="100%">
        <pattern id="hex-pattern" x="0" y="0" width="16" height="16" patternUnits="userSpaceOnUse">
          <path d="M8 1L15 4.5V11.5L8 15L1 11.5V4.5L8 1Z" stroke="currentColor" fill="none"/>
        </pattern>
        <rect width="100%" height="100%" fill="url(#hex-pattern)"/>
      </svg>
    </div>
  );

  if (style === 'simple') {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-xl font-bold mb-4">{gameTitle}</h3>
        {showPrizePool && (
          <div className="flex items-center text-gray-700 mb-4">
            <Trophy className="w-5 h-5 mr-2 text-gray-500" />
            <span className="font-medium">Prize Pool: {getPrizePool()}</span>
          </div>
        )}
        {(game?.deadline || deadline) && (
          <div className="flex items-center text-gray-700 mb-4">
            <Calendar className="w-5 h-5 mr-2 text-gray-500" />
            <span className="font-medium">Deadline: {game?.deadline || deadline}</span>
          </div>
        )}
        <button 
          onClick={() => navigate(game?.route || links[0]?.to)}
          className={`w-full bg-${primaryColor || 'purple-600'} text-white py-2 rounded-lg hover:bg-opacity-90 transition-colors`}
        >
          Play Game
        </button>
      </div>
    );
  }

  // Detailed style (your existing design)
  return (
    <div className="relative overflow-hidden rounded-xl bg-white shadow-lg transition-all duration-300 hover:shadow-xl">
      {/* Header Section */}
      <div className={`relative h-28 ${getBannerStyle()} p-6 flex items-center`}>
        {getPatternOverlay()}
        <h3 className="relative text-2xl font-bold text-white leading-tight">
          {gameTitle}
        </h3>
      </div>

      {/* Content */}
      <div className="p-6">
        <div className="space-y-4">
          {showPrizePool && (
            <div className="flex items-center text-gray-700">
              <Trophy className="w-5 h-5 mr-2 text-gray-500" />
              <span className="font-medium">Prize Pool: {getPrizePool()}</span>
            </div>
          )}

          {getGameType() === 'football' && entryCount > 0 && (
            <div className="flex items-center text-gray-700">
              <Users className="w-5 h-5 mr-2 text-gray-500" />
              <span className="font-medium">{entryCount} Entries</span>
            </div>
          )}

          {(game?.deadline || deadline) && (
            <div className="flex items-center text-gray-700">
              <Calendar className="w-5 h-5 mr-2 text-gray-500" />
              <span className="font-medium">Deadline: {game?.deadline || deadline}</span>
            </div>
          )}

          {game?.description && (
            <div className="flex items-center text-gray-700">
              <span className="font-medium">{game.description}</span>
            </div>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex flex-wrap gap-2 mt-6">
          {links ? (
            links.map((link, index) => (
              <Link
                key={index}
                to={link.to}
                className="rounded-lg bg-purple-600 px-6 py-2 text-sm font-medium text-white transition-colors hover:bg-purple-700 active:bg-purple-800"
              >
                {link.text}
              </Link>
            ))
          ) : (
            <Link
              to={game.route}
              className={`rounded-lg bg-${primaryColor || 'purple-600'} px-6 py-2 text-sm font-medium text-white transition-colors hover:bg-opacity-90`}
            >
              Play Game
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameCard;